import { Suspense } from "react";
import Lottie from "react-lottie-player";
import { Skeleton } from "@reftree/ui";
import { smoothScrollToElementById } from "@/utils/dom";
import heroIllustrationLottie from "../../assets/hero-illustration-lottie.json";

interface HomeHeroProps {
  scrollToSectionId: string;
}

export default function HomeHero({ scrollToSectionId }: HomeHeroProps) {
  return (
    <section className="container pt-4">
      <div className="flex flex-col md:flex-row gap-y-10 gap-x-10 md:justify-between">
        <div className="w-full flex items-center justify-center">
          <div className="">
            <h1 className="mb-5 text-4xl text-center md:text-left max-w-md mx-auto md:max-w-none font-inter font-extrabold text-neutral-800 lg:text-6xl">
              Referral programs you can rely on
            </h1>
            <p className="max-w-lg text-center md:text-left text-neutral-600 text-lg">
              Empower your users to share your product or service with their
              network and earn rewards. Run referral programs directly inside
              your product for a seamless experience.
            </p>
            <div className="flex flex-col justify-center md:justify-start gap-2 min-[400px]:flex-row mt-4">
              <button
                onClick={() => smoothScrollToElementById(scrollToSectionId)}
                className="font-inter border border-neutral-400 hover:border-primary hover:bg-primary font-semibold px-6 py-2 rounded-md text-neutral-800 hover:text-white transition-all duration-300"
              >
                Find out more
              </button>
            </div>
          </div>
        </div>
        <div className="w-full">
          <Suspense
            fallback={
              <Skeleton className="w-full h-[100vw] md:h-[400px] md:w-[400px] lg:h-[500px] lg:w-[500px] xl:w-[600px] xl:h-[600px]" />
            }
          >
            <Lottie
              loop
              className="w-full h-[100vw] md:h-[400px] md:w-[400px] lg:h-[500px] lg:w-[500px] xl:w-[600px] xl:h-[600px]"
              animationData={heroIllustrationLottie}
              play
            />
          </Suspense>
        </div>
      </div>
    </section>
  );
}
