export function smoothScrollToElementById(id: string) {
  const target = document.getElementById(id);

  if (!target) {
    console.warn(
      "Cannot smooth scroll to target as it does not exist in the DOM."
    );
    return;
  }

  target.scrollIntoView({ behavior: "smooth" });
}
